import React from 'react'
import img1 from '../../assets/doodles/1.jpg';
import img2 from '../../assets/doodles/2.jpg';
import img3 from '../../assets/doodles/3.jpg';
import img4 from '../../assets/doodles/4.jpg';
import img5 from '../../assets/doodles/5.jpg';
import img6 from '../../assets/doodles/6.jpg';
import img7 from '../../assets/doodles/7.jpg';
import img8 from '../../assets/doodles/8.jpg';
import img9 from '../../assets/doodles/9.jpg';
import img10 from '../../assets/doodles/10.jpg';
import img11 from '../../assets/doodles/11.jpg';
import img12 from '../../assets/doodles/12.jpg';
import img13 from '../../assets/doodles/13.jpg';
import img14 from '../../assets/doodles/14.jpg';
import img15 from '../../assets/doodles/15.jpg';
import img16 from '../../assets/doodles/16.jpg';
import img17 from '../../assets/doodles/17.jpg';
import img18 from '../../assets/doodles/18.jpg';
import img19 from '../../assets/doodles/19.jpg';
import img20 from '../../assets/doodles/20.jpg';
import img21 from '../../assets/doodles/21.jpg';
import img22 from '../../assets/doodles/22.jpg';
import img23 from '../../assets/doodles/23.jpg';
import img24 from '../../assets/doodles/24.jpg';
import img25 from '../../assets/doodles/25.jpg';
import img26 from '../../assets/doodles/26.jpg';
import img27 from '../../assets/doodles/27.jpg';
import img28 from '../../assets/doodles/28.jpg';
import img29 from '../../assets/doodles/29.jpg';
import img30 from '../../assets/doodles/30.jpg';
import img31 from '../../assets/doodles/31.jpg';
import img32 from '../../assets/doodles/32.jpg';
import img33 from '../../assets/doodles/33.jpg';
import img34 from '../../assets/doodles/34.jpg';
import img35 from '../../assets/doodles/35.jpg';
import img36 from '../../assets/doodles/36.jpg';
import img37 from '../../assets/doodles/37.jpg';
import img38 from '../../assets/doodles/38.jpg';
import img39 from '../../assets/doodles/39.jpg';
import img40 from '../../assets/doodles/40.jpg';
import img41 from '../../assets/doodles/41.jpg';
import img42 from '../../assets/doodles/42.jpg';
import './ImageCloud.scss';

const imgArray = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42]

const shuffle = (array) => {
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default function ImageCloud(children) {
  return (
    <React.Fragment>
      <div className="cloud">
        {imgArray.length > 0 ? shuffle(imgArray).slice(0, 36).map(img => {
          return (
            <img key={img} src={img} />
          )
        }) : ""}
      </div>
    </React.Fragment>
  )
}
