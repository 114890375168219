import React, { useState } from 'react';
import './Contact.scss';
import bg from '../../assets/desk contact.svg';
import InquiryForm from './Inquiry'
import QuoteForm from './Quote'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProjectCard from '../../components/cards/project/ProjectCard';

export default function ContactPage() {
  let [portfolioItems, setPortfolioItems] = useState(null)
  let [serviceInterest, setServiceInterest] = useState([])
  let [wizard_open, setWizard_open] = useState(false)
  let [error, setError] = useState(null)

  const loadRelevant = () => {
    const getPortfolioItems = async () => {
      let resp = await fetch('https://api.kylediggs.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            projects (where: {_or: [
              {category: "design"},
              {category: "development"}
            ]}) {
                id
                title
                slug
                description
                category
                image {
                  url
                  ext
                }
                technologies {
                  id
                  slug
                  name
                  label
                }
            }
        }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting project info")
      }
      setPortfolioItems(resp?.data?.projects ? resp.data.projects : null);
      setWizard_open(true);
    }

    getPortfolioItems()
  }

  const toggleInterest = (interest) => {
    if (serviceInterest.includes(interest)) {
      setServiceInterest(serviceInterest.filter(item => item != interest));
    } else {
      setServiceInterest([...serviceInterest, interest]);
    }
  }

  const openWizard = () => {
    if (wizard_open) setWizard_open(false)
    else {
      loadRelevant()
    }
  }

  return (
    <React.Fragment>
      <section className="contact">
        <div className="container">
          <div className="contact-info">
            <div className="intro">
              <h2>Howdy!</h2>
              <p>Welcome to my contact page. Here you can submit a work ticket, reach out with an inquiry, request a quote, or request additional information or code samples.</p>
              <p>You are also more than welcome to reach out at any point via email. I'd love to hear from you :)</p>
            </div>
            <div className="email"><i className="fas fa-mail-bulk"></i><a href="mailto:contact@kylediggs.com">Contact@KyleDiggs.com</a></div>
          </div>
          <Tabs onSelect={() => { setWizard_open(false) }}>
            <TabList>
              <Tab><button className="button clean"><i className="fas fa-mail-bulk"></i> General Inquiry</button></Tab>
              <Tab><button className="button clean"><i className="fas fa-file-invoice"></i> Quote Request</button></Tab>
            </TabList>
            <TabPanel>
              <InquiryForm onSelectedServices={openWizard} serviceInterest={serviceInterest} />
            </TabPanel>
            <TabPanel>
              <QuoteForm serviceInterest={serviceInterest} />
            </TabPanel>
            <section className="service-interest">
              <div className={wizard_open ? "container" : "hide container"}>
                {portfolioItems?.length > 0 ? portfolioItems.map(service => {
                  return (
                    <div key={service.id} className={serviceInterest.includes(service.title) ? "service selected button clean" : "service button  clean"} value={service.title} onClick={() => { toggleInterest(service.title) }} >
                      <ProjectCard project={service} />
                    </div>
                  )
                }) : ""}
              </div>
            </section>
          </Tabs>
        </div>
      </section>
    </React.Fragment>
  )
}
