let data = [{
        "name": "Adobe XD",
        "label": "XD",
        "link": "",
        "imagePath": require("./resumeimages/adobexd.svg")
    },
    {
        "name": "Adobe Illustrator",
        "label": "Illustrator",
        "link": "",
        "imagePath": require("./resumeimages/adobeillustrator.svg")
    },
    {
        "name": "Adobe Photoshop",
        "label": "Photoshop",
        "link": "",
        "imagePath": require("./resumeimages/adobephotoshop.svg")
    },
    {
        "name": "Adobe After Effects",
        "label": "After Effects",
        "link": "",
        "imagePath": require("./resumeimages/adobeaftereffects.svg")
    },
    {
        "name": "Visual Studio Code",
        "label": "VSC",
        "link": "",
        "imagePath": require("./resumeimages/visualstudiocode.svg")
    },
    {
        "name": "Postman",
        "label": "Postman",
        "link": "",
        "imagePath": require("./resumeimages/postman.svg")
    },
    {
        "name": "Git",
        "label": "Git",
        "link": "",
        "imagePath": require("./resumeimages/git.svg")
    },
    {
        "name": "Bootstrap",
        "label": "Bootstrap 3/4",
        "link": "",
        "imagePath": require("./resumeimages/bootstrap.svg")
    },
    {
        "name": "Webflow",
        "label": "Webflow",
        "link": "",
        "imagePath": require("./resumeimages/webflow.svg")
    },
    {
        "name": "Shopify",
        "label": "Shopify",
        "link": "",
        "imagePath": require("./resumeimages/shopify.svg")
    },
    {
        "name": "WooCommerce",
        "label": "WooCommerce",
        "link": "",
        "imagePath": require("./resumeimages/woo.svg")
    },
    {
        "name": "WordPress",
        "label": "WordPress",
        "link": "",
        "imagePath": require("./resumeimages/wordpress.svg")
    },
    {
        "name": "Google Cloud",
        "label": "Google Cloud",
        "link": "",
        "imagePath": require("./resumeimages/googlecloud.svg")
    },
    {
        "name": "Google Analytics",
        "label": "Analytics",
        "link": "",
        "imagePath": require("./resumeimages/googleanalytics.svg")
    },
    {
        "name": "Adobe Premiere Pro",
        "label": "Premiere",
        "link": "",
        "imagePath": require("./resumeimages/adobepremierepro.svg")
    },
    {
        "name": "React Router",
        "label": "React Router",
        "link": "",
        "imagePath": require("./resumeimages/adobepremierepro.svg")
    },
    {
        "name": "React",
        "label": "React",
        "link": "",
        "imagePath": require("./resumeimages/react.svg")
    }
]

export default data;