import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import LanguageBadge from '../../components/badges/language/LanguageBadge';
import SoftwareBadge from '../../components/badges/software/SoftwareBadge';
import TopicBadge from '../../components/badges/topic/TopicBadge';
import BlogCard from '../../components/cards/blog/BlogCard';
import ProjectCard from '../../components/cards/project/ProjectCard';
import ImageCloud from '../../components/imagecloud/ImageCloud';
import LogoBall from '../../components/logo-ball/LogoBall';
import './Filter.scss';

export default function Filter() {
  let [error, setError] = useState(null)
  let [filter, setFilter] = useState(null)
  let [relatedProjects, setRelatedProjects] = useState(null)
  let [loading, setLoading] = useState(true)
  let [topics, setTopics] = useState(null)
  let [languages, setLanguages] = useState(null)
  let [software, setSoftware] = useState(null)
  let location = useLocation();

  useEffect(() => {
    const getData = async () => {
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            topics {
              data {
                id
                attributes {
                  title
                  slug
                }
              }
            }
            languages {
              data {
                id
                attributes {
                  name
                  slug
                  label
                  experience_level
                  first_usage
                }
              }
            }
            technologies {
              data {
                id
                attributes {
                  slug
                  name
                  label
                  link
                  image {
                    data {
                      attributes {
                        url
                        ext
                      }
                    }
                  }
                }
              }
            }
          }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting language info")
      }
      setTopics(resp?.data?.topics ? resp.data.topics : null);
      setLanguages(resp?.data?.languages ? resp.data.languages : null);
      setSoftware(resp?.data?.technologies ? resp.data.technologies : null);
    }

    getData()
  }, [])

  useEffect(() => {
    const getFilter = async () => {
      let slugTarget = location.pathname.split("/")[location.pathname.split("/").length - 1]
      console.log(slugTarget)
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            technologies (
              sort: "publishedAt:desc",
              filters: {slug: {eq: "${slugTarget}"}}
              ) {
                data {
                  id
                  attributes {
                    name
                    description
                    slug
                    label
                    related {
                      data {
                        id
                        attributes {
                          name
                          description
                          slug
                          label
                        }
                      }
                    }
                    projects {
                      data {
                        id
                        attributes {
                          title
                          description
                          image {
                            data {
                              id
                              attributes {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              languages (
                sort: "publishedAt:desc",
                filters: {slug: {eq:"${slugTarget}"}}
                ) {
                  data {
                    id
                    attributes {
                      name
                      description
                      slug
                      label
                      first_usage
                      experience_level
                    }
                  }
                }
              blogs (
                sort: "publishedAt:desc", 
                filters: {or: [
                  {languages: {slug: {eq:"${slugTarget}"}}},
                  {technologies: {slug: {eq:"${slugTarget}"}}}
                ]}
                ) {
                data {
                  id
                  attributes {
                    title
                    website
                    github
                    slug
                    content
                    image {
                      data {
                        id
                        attributes {
                          url
                        }
                      }
                    }
                    technologies {
                      data {
                        id
                        attributes {
                          slug
                          name
                          label
                        }
                      }
                    }
                  }
                }
            }
          }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting topic info")
      }
      console.log(resp.data)
      setFilter(resp?.data ? resp.data : null);
    }

    getFilter()
  }, [])

  useEffect(() => {
    const getRelatedProjects = async () => {
      let technologiesArr = filter?.technologies?.data?.length > 0 ? filter.technologies.data[0].attributes.related.data.map(relate => {
        return '"' + relate.attributes.slug + '"'
      }).filter(x => x).join(",") : ""

      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            projects (
              sort: "publishedAt:desc", 
              filters: {or: [
                {technologies: {slug: {in: [${technologiesArr}]}}},
                {languages: {slug: {eq:"` + (filter?.languages?.data?.length > 0 ? filter?.languages?.data[0].attributes.slug : "") + `"}}}
              ]
            }) {
              data {
                id
                attributes {
                  title
                  description
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting topic info")
      }
      console.log(resp?.data)
      setRelatedProjects(resp?.data?.projects ? resp.data.projects : []);
    }

    if (filter?.technologies?.data?.length > 0 || filter?.languages?.data?.length > 0) {
      getRelatedProjects()
    }
  }, [filter])

  useEffect(() => {
    if (relatedProjects?.data?.length > 0) {
      setLoading(false)
    }
  }, [relatedProjects])

  return (
    <React.Fragment>
      {filter && !loading ? <React.Fragment>
        <section className="section-head container col">
          <div className="topic-showcase container card ">
            {filter?.technologies?.data?.length > 0
              ? <React.Fragment>
                <div className="title">
                  <h1>{filter.technologies.data[0].attributes.name}</h1>
                  <p>{filter.technologies.data[0].attributes.description}</p>
                </div>
              </React.Fragment>
              : filter?.languages?.data?.length > 0
                ? <React.Fragment>
                  <div className="title">
                    <h1>{filter.languages.data[0].attributes.name}</h1>
                    <p>{filter.languages.data[0].attributes.description}</p>
                  </div>
                </React.Fragment>
                : ""}
            <div className="badges center">
              {filter?.technologies?.data?.length > 0 && filter?.technologies.data[0].related?.data?.length > 0
                ? filter.technologies.data[0].related.data.map(soft => {
                  return (
                    <SoftwareBadge software={soft} />
                  )
                }) : filter?.languages?.data?.length > 0 ? filter.languages.data.map(lang => {
                  return (
                    <LanguageBadge language={lang} />
                  )
                }) : ""}
            </div>
          </div>
        </section>
        <section className="topic portfolio-cards">
          <div className="container">
            {filter?.blogs?.data?.length > 0 ? filter.blogs.data.map(blog => {
              return (
                <BlogCard blog={blog} />
              )
            }) : ""}
          </div>
        </section>
        <section className="cta">
          <div className="title">
            <p>That's it for my write-ups</p>
            <h2>Services related to {filter?.languages?.data?.length > 0 ? filter.languages.data[0].attributes.name : ""}{filter?.technologies?.data?.length > 0 ? filter.technologies.data[0].attributes.name : ""}</h2>
            <p>Check out some that I offer below. <br /><br />OR</p>
            <a href="/contact"><div className="button">Get In Touch</div></a>
          </div>
          <ImageCloud />
        </section>
        <section className="topic portfolio-cards">
          <div className="container">
            {relatedProjects?.data?.length > 0 ? relatedProjects.data.map(project => {
              return (
                <ProjectCard project={project} />
              )
            }) : ""}
          </div>
        </section>
        <section className="cta">
          <div className="title container">
            <h2>Looking for something more specific?</h2>
            <p>Browse my portfolio by these use cases</p>
            <div className="badges">
              {topics?.data?.length > 0 ? topics.data.map(topic => {
                return (
                  <TopicBadge key={topic.id} topic={topic} />
                )
              }) : ""}
            </div>
            <p>Browse my portfolio by these technologies and languages</p>
            <div className="badges">
              {languages?.data?.length > 0 ? languages.data.map(lang => {
                return (
                  <LanguageBadge key={lang.id} language={lang} />
                )
              }) : ""}
              {software?.data?.length > 0 ? software.data.map(soft => {
                return (
                  <SoftwareBadge key={soft.id} software={soft} />
                )
              }) : ""}
            </div>
            <p>Or get in touch!</p>
            <a href="/contact"><div className="button">Contact Me</div></a>
          </div>
          <ImageCloud />
        </section>
      </React.Fragment> : <React.Fragment>
        <section className="loading">
          <div className="container center">
            <LogoBall />
          </div>
        </section>
      </React.Fragment>
      }
    </React.Fragment >
  )
}
