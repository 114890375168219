import React from 'react';
import '../../app/App.scss';
import './Rotator.scss';
import { Route, Switch, withRouter, Router } from 'react-router-dom'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (<React.Fragment>
            <div className={"rotator r" + this.props.items.length}>
                {this.props.items.map((im, ind) => {
                    return (
                        <div className="image-effects"><img src={"https://api.kylediggs.com" + im} /></div>
                    )
                })}
            </div>
        </React.Fragment>)
    }
}

export default withRouter(App);