import React, { useState } from 'react';
import './Header.scss';
import logo from '../../assets/logo.svg';
import cheeseburger from '../../assets/cheeseburger.svg';
import cheeseburgerBiting from '../../assets/cheeseburger-biting.svg';
import cheeseburgerBit from '../../assets/cheeseburger-bit.svg';
import cheeseburgerClose from '../../assets/cheeseburger-close.svg';
import { Link } from 'react-router-dom';

export const navLinks = () => {
  let navLinks = [
    { label: "Home", path: "/" },
    { label: "Design", path: "/topic/graphic-design" },
    { label: "Development", path: "/topic/website-development" },
    { label: "Resume", path: "/resume" },
    { label: "Contact", path: "/contact" }
  ]
  return (navLinks)
}


export default function Header() {
  let [hamburger_isOpen, setHamburger_isOpen] = useState(false);


  const toggleBurg = () => {
    const currentState = hamburger_isOpen;
    setHamburger_isOpen(!hamburger_isOpen);
  }

  return (<React.Fragment>
    <section className="header">
      <div className="container">
        <img className="logo" src={logo} alt="logo" />
        <div className={hamburger_isOpen == false ? "cheesburg" : "cheesburg open"} onClick={toggleBurg}>
          <img className="pre" src={cheeseburger} alt="logo" />
          <img className="biting" src={cheeseburgerBiting} alt="logo" />
          <img className="bit" src={cheeseburgerBit} alt="logo" />
          <img className="close" src={cheeseburgerClose} alt="logo" />
        </div>
        <ul>
          {navLinks().map(link => {
            return (
              <Link key={link.label} onClick={() => toggleBurg()} className="button clean" to={link.path}>{link.label}</Link>
            )
          })}
        </ul>
      </div>
    </section>
  </React.Fragment>);
}
