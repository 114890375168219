import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../design/Design.scss"
import '../../components/badges/Badge.scss';
import "./Topic.scss";

import ImageCloud from '../../components/imagecloud/ImageCloud'

import Rotator from '../../components/rotator/Rotator'
import LanguageBadge from '../../components/badges/language/LanguageBadge';
import SoftwareBadge from '../../components/badges/software/SoftwareBadge';
import TopicBadge from '../../components/badges/topic/TopicBadge';
import BlogCard from '../../components/cards/blog/BlogCard';
import ProjectCard from '../../components/cards/project/ProjectCard';
import HelmetSeo from '../../components/seo/seo';

export default function Topic() {
  let [topic, setTopic] = useState(null)
  let [topics, setTopics] = useState(null)
  let [languages, setLanguages] = useState(null)
  let [software, setSoftware] = useState(null)
  let [error, setError] = useState(null)
  let location = useLocation();

  let [seo, setSEO] = useState(null)
  let [opengraph, setOpenGraph] = useState(null)
  let [twitter, setTwitter] = useState(null)

  useEffect(() => {
    const getImageCloudData = async () => {
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            topics {
              data {
                id
                attributes {
                  title
                  slug
                }
              }
            }
            languages {
              data {
                id
                attributes {
                  name
                  slug
                  label
                  experience_level
                  first_usage
                }
              }
            }
            technologies {
              data {
                id
                attributes {
                  slug
                  name
                  label
                  link
                  image {
                    data {
                      id
                      attributes {
                        url
                        ext
                      }
                  }
                }
              }
            }
          }
        }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting language info")
      }
      setTopics(resp?.data?.topics ? resp.data.topics : null);
      setLanguages(resp?.data?.languages ? resp.data.languages : null);
      setSoftware(resp?.data?.technologies ? resp.data.technologies : null);
    }

    getImageCloudData()
  }, [])

  useEffect(() => {
    let slugTarget = location.pathname.split("/")[location.pathname.split("/").length - 1];
    const getTopic = async () => {
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query {
            topics(
              sort: "publishedAt:desc",
              filters: { slug: { eq: "${slugTarget}" } }
            ) {
              data {
                id
                attributes {
                  title
                  description
                  slug
                  technologies {
                    data {
                      id
                      attributes {
                        name
                        slug
                        label
                      }
                    }
                  }
                  languages {
                    data {
                      id
                      attributes {
                        name
                        slug
                        label
                        first_usage
                        experience_level
                      }
                    }
                  }
                  projects(sort: "publishedAt:desc") {
                    data {
                      id
                      attributes {
                        title
                        description
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  blogs(sort: "publishedAt:desc") {
                    data {
                      id
                      attributes {
                        title
                        slug
                        content
                        website
                        github
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  technologies {
                    data {
                      id
                      attributes {
                        slug
                        name
                        label
                      }
                    }
                  }
                }
              }
            }
          }
          `}),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting topic info")
      }
      setTopic(resp?.data?.topics ? resp.data.topics.data[0] : null);
      setSEO(resp?.data?.topics ? resp?.data?.topics.data[0].seo : null)
      setOpenGraph(resp?.data?.topics ? resp?.data?.topics.data[0].opengraph : null)
      setTwitter(resp?.data?.topics ? resp?.data?.topics.data[0].twitter : null)
    }

    getTopic()
  }, [location])

  return (<React.Fragment>
    {topic ?
      <section className="section-head container col">
        <div className="topic-showcase container card ">
          <div className="title">
            <h1>{topic.attributes.title}</h1>
            <p>{topic.attributes.description}</p>
          </div>
          <div className={(topic.attributes.languages.length + topic.attributes.technologies.length) > 10 ? "badges center too-long" : "badges center"} >
            {topic?.attributes?.languages?.data?.length > 0 ? topic.attributes.languages.data.map(lang => {
              return (
                <LanguageBadge key={lang.id} language={lang} />
              )
            }) : ""}
            {topic?.attributes?.technologies?.data?.length > 0 ? topic.attributes.technologies.data.map(tech => {
              return (
                <SoftwareBadge key={tech.id} software={tech} />
              )
            }) : ""}
          </div>
        </div>
      </section>
      : ""}
    {topic?.attributes?.blogs?.data?.length > 0 ? <React.Fragment>
      <section className="topic portfolio-cards">
        <div className="container">
          {topic?.attributes?.blogs?.data?.length > 0 ? topic.attributes.blogs.data.map(blog => {
            return (
              <BlogCard key={blog.id} blog={blog} />
            )
          }) : ""}
        </div>
      </section>
      <section className="cta">
        <div className="title">
          <p>That's it for my write-ups</p>
          <h2>Services related to {topic ? topic.attributes.title : "..."}</h2>
          <p>Check out some that I offer below. <br /><br />OR</p>
          <a href="/contact"><div className="button">Get In Touch</div></a>
        </div>
        <ImageCloud />
      </section>
    </React.Fragment> : ""}
    <section className="topic portfolio-cards">
      <div className="container">
        {topic?.attributes?.projects?.data?.length > 0 ? topic.attributes.projects.data.map(project => {
          return (
            <ProjectCard key={project.id} project={project} />
          )
        }) : ""}
      </div>
    </section>
    <section className="cta">
      <div className="title container">
        <h2>Looking for something more specific?</h2>
        <p>Browse my portfolio by these use cases</p>
        <div className="badges">
          {topics?.data?.length > 0 ? topics.data.map(topic => {
            return (
              <TopicBadge key={topic.id} topic={topic} />
            )
          }) : ""}
        </div>
        <p>Browse my portfolio by these technologies and languages</p>
        <div className="badges">
          {languages?.data?.length > 0 ? languages.data.map(lang => {
            return (
              <LanguageBadge key={lang.id} language={lang} />
            )
          }) : ""}
          {software?.data?.length > 0 ? software.data.map(soft => {
            return (
              <SoftwareBadge key={soft.id} software={soft} />
            )
          }) : ""}
        </div>
        <p>Or get in touch!</p>
        <a href="/contact"><div className="button">Contact Me</div></a>
      </div>
      <ImageCloud />
    </section>
  </React.Fragment >)
}
