import React, { useState } from 'react'

export default function ProjectCard(props) {
  let [project] = useState(props.project)

  return (
    <React.Fragment>
      <div key={project.id} className="card">
        <a>
          <div className="img-con">
            <img className="image" src={project.attributes.image.data.attributes.url} />
          </div>
          <h5 className="title">{project.attributes.title}</h5>
          <p className="content">{project.attributes.description}</p>
        </a>
      </div>
    </React.Fragment>
  )
}
