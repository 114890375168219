import React, { useState, useEffect } from 'react';
import './Landing.scss';
import '../design/Design.scss'

import '../../components/badges/Badge.scss';
import '../topic/Topic.scss'
import '../../components/showcase/Showcase.scss'
import '../../components/cards/Card.scss';
import logo from '../../assets/logo.svg';
import { Link } from 'react-router-dom'

import mario from '../../assets/mario.svg';
import LogoBall from '../../components/logo-ball/LogoBall';

import BlogCards from '../../components/cards/blog/BlogCards';
import ProjectCards from '../../components/cards/project/ProjectCards';

import TopicBadge from '../../components/badges/topic/TopicBadge';
import LanguageBadge from '../../components/badges/language/LanguageBadge';
import SoftwareBadge from '../../components/badges/software/SoftwareBadge';
import TopicCards from '../../components/cards/topic/TopicCards';
import LogoCard from '../../components/cards/logo/LogoCard';
import ProjectCard from '../../components/cards/project/ProjectCard';
import BlogCard from '../../components/cards/blog/BlogCard';
import TestimonialCard from '../../components/cards/testimonial/TestimonialCard';
import ImageCloud from '../../components/imagecloud/ImageCloud';


export default function LandingPage() {
  let [showWizard, setShowWizard] = useState(false)
  let [searchResults, setSearchResults] = useState(null)
  let [featured, setFeatured] = useState(null)
  let [languages, setLanguages] = useState(null)
  let [topics, setTopics] = useState(null)
  let [error, setError] = useState(null)
  let [software, setSoftware] = useState(null)
  let [loading, setLoading] = useState(false)

  console.log(process.env)

  useEffect(() => {
    const getData = async () => {
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            topics {
              data {
                id
                attributes {
                  title
                  slug
                }
              }
            }
            languages {
              data {
                id
                attributes {
                  name
                  slug
                  label
                  experience_level
                  first_usage
                }
              }
            }
            technologies {
              data {
                id
                attributes {
                  slug
                  name
                  label
                  link
                  image {
                    data {
                      id
                      attributes {
                        url
                        ext
                      }
                    }
                  }
                }
              }
            }
          }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting language info")
      }
      setTopics(resp?.data?.topics ? resp.data.topics : null);
      setLanguages(resp?.data?.languages ? resp.data.languages : null);
      setSoftware(resp?.data?.technologies ? resp.data.technologies : null);
    }

    getData()
  }, [])

  useEffect(() => {
    const getFeaturedData = async () => {
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            blogs (
              sort: "publishedAt:desc", 
              filters: {website: {gt: ""}}
            ) {
              data {
                id
                attributes {
                  title
                  content
                  slug
                  website
                  github
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  technologies {
                    data {
                      id
                      attributes {
                        name
                        slug
                        label
                      }
                    }
                  }
                }
              }
            }
            testimonials {
              data {
                id
                attributes {
                  author
                  position
                  content
                }
              }
            }
            projects (filters: {or: [
              {category: { eq: "logodesign"}},
              {category: { eq: "graphicdesign"}},
              {category: { eq: "design"}}
            ]},
              pagination: {limit: 100}
            ) {
              data {
                id
                attributes {
                  category
                  title
                  description
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting language info")
      }

      console.log(resp)
      setFeatured(resp?.data ? resp.data : null);
    }

    getFeaturedData()
  }, [])


  const searchSite = async () => {
    setLoading(true)
    let query = document.getElementById("SearchPort").value;
    let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `query{
          projects (filters: {or: [
            {title: {containsi: "` + query + `"}},
            {technologies: {name: {containsi: "` + query + `"}}}
          ]}) {
            data {
              id
              attributes {
                title
                description
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          blogs (filters: {or: [
            {title_contains: {eq: "` + query + `"}},
            {technologies: {name: {containsi: "` + query + `"}}},
            {topics: {languages: {name: {containsi: "` + query + `"}}}}
          ]}) {
            data {
              id
              attributes {
                title
                content
                slug
                website
                github
                publishedAt
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                technologies {
                  data {
                    id
                    attributes {
                      name
                      slug
                      label
                    }
                  }
                }
              }
            }
          }
          topics (filters: {or: [
            {title: {containsi: "` + query + `"}},
            {description: {containsi: "` + query + `"}},
            {technologies: {name: {containsi: "` + query + `"}}},
            {languages: {name: {containsi: "` + query + `"}}}
          ]}) {
            data {
              id
              attributes {
                title
                slug
              }
            }
          }
        }
  ` }),
    }).then(resp => resp.json());
    setShowWizard(false)
    let timeout = setTimeout(() => {
      setLoading(false)
      clearTimeout(timeout);
      setSearchResults(resp.data)
    }, 1000);
  }

  const toggleSugg = () => {
    setShowWizard(!showWizard)
  }

  const updateSearch = (query) => {
    document.getElementById("SearchPort").value = query;
    searchSite();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchSite();
    }
  }

  const fadeIn = (e) => {
    e.target.style.opacity = 1
  }

  return (<React.Fragment>
    <section className="chooser">
      <div className="top">
        <img className="logo" onLoad={(e) => fadeIn(e)} src={logo} alt="Kyle Diggs Development and Design" />
        <h1><a href="/topic/website-development">Development</a> and <a href="/topic/graphic-design">Design</a></h1>
        <h5>Full-Stack Web and Digital Media Specialist</h5>
      </div>
      <label className="search-con">
        <div className={showWizard ? "search-input show" : "search-input"}>
          <input onKeyDown={handleKeyDown} id="SearchPort" className="form-element" type="text" placeholder="Search Portfolio"></input>
          <div className={showWizard ? "badges show" : "badges"} >
            <h5>Search by topic</h5>
            {topics?.data?.length > 0 ? topics.data.map(topic => {
              return (
                <TopicBadge key={topic.id} topic={topic} />
              )
            }) : ""}
            <h5>Search by language</h5>
            {languages?.data?.length > 0 ? languages.data.map(lang => {
              return (
                <LanguageBadge key={lang.id} language={lang} />
              )
            }) : ""}
            <h5>Search by technology or software</h5>
            {software?.data?.length > 0 ? software.data.map(soft => {
              return (
                <SoftwareBadge key={soft.id} software={soft} />
              )
            }) : ""}
          </div>
          <div className="sugg-but-con" onClick={() => toggleSugg()}>
            <button onClick={() => toggleSugg()} className="button sugg-but">
              <svg onClick={() => toggleSugg()} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="svg-inline--fa fa-question fa-w-12 fa-7x">
                <path onClick={() => toggleSugg()} fill="currentColor" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z" className=""></path></svg>
              <img onClick={() => toggleSugg()} className="mario" src={mario} alt="mario-easter-egg" />
            </button>
          </div>
          <button onClick={() => searchSite()} className="button search-but"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-search fa-w-16 fa-7x"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" className=""></path></svg></button>
        </div>
      </label>
      <div className="button-con">
        <Link to="/topic/website-development">
          <button className="button">Development Portfolio</button>
        </Link>
        <Link to="/topic/graphic-design">
          <button className="button">Design Portfolio</button>
        </Link>
        <Link to="/resume">
          <button className="button">Resume</button>
        </Link>
        <Link to="/contact">
          <button className="button">Contact</button>
        </Link>
      </div>
      {loading ? <div className="loader"><LogoBall /></div> : ""}
      <section className="search-results portfolio-cards">
        {searchResults?.topics?.length >= 1 ? <React.Fragment>
          <TopicCards topics={searchResults?.topics} />
        </React.Fragment> : ""}
        {searchResults?.blogs?.length >= 1 ? <React.Fragment>
          <BlogCards blogs={searchResults.blogs} />
        </React.Fragment> : ""}
        {searchResults?.projects?.length >= 1 ? <React.Fragment>
          <ProjectCards projects={searchResults?.projects} />
        </React.Fragment> : ""}
        {searchResults?.projects?.length == 0 && searchResults?.blogs?.length == 0 && searchResults?.topics?.length == 0 ? <React.Fragment>
          <div className="topic-showcase container card">
            <div className="title">
              <h1>Darn!</h1>
              <p>No results were found. If you think this is something I can handle feel free to reach out! Below is my Condensed portfolio if you'd like to look around a bit.</p>
            </div>
          </div>
        </React.Fragment> : ""}
        {true ? <React.Fragment>
          <div className="search-results">

            <div className="intro container card purp">
              <div className="title">
                <h3>I'm Currently...</h3>
                <span>Available</span>
                <p>for full-time, part-time, contract, or remote work</p>
              </div>
            </div>
            <div className="intro container card purp">
              <div className="title">
                <h3>My Schedule is...</h3>
                <span>Open</span>
                <p>Your project could be started in as little as 2 weeks</p>
              </div>
            </div>
            <div className="intro container card purp">
              <div className="title">
                <h3>I'm Looking For...</h3>
                <span className="compact">Custom React Development • Graphic Design • E-Commerce • Strapi Development • Website Design • Custom Illustrated Animations • Custom Private Business Applications • And More...</span>
              </div>
            </div>
          </div>
        </React.Fragment> : ""}
      </section>

      <div className="scroll-ind">
        <p>Condensed Portfolio</p>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-arrow-down fa-w-14 fa-5x"><path fill="currentColor" d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z" className=""></path></svg>
      </div>

    </section>
    <section className="featured">
      <div className="topic-showcase container card purp">
        <div className="title">
          <h1>Recent Web Development</h1>
          <h5>$75/hr</h5>
          <p>Fixed pricing for fixed scope</p>
        </div>
        <section className="search-results portfolio-cards">
          {featured?.blogs?.data.length >= 1 ? featured.blogs.data.map(blog => {
            return (
              <BlogCard key={blog.id} blog={blog} />
            )
          }) : ""}
        </section>
      </div>
    </section>
    <section className="cta small-cloud">
      <div className="title">
        <h2>Like what you see?</h2>
        <p>Get in touch!</p>
        <a href="/contact"><div className="button">Contact Me</div></a>
      </div>
      <ImageCloud />
    </section>
    <section className="chooser">
      <div className="topic-showcase logo-design container card purp">
        <div className="title">
          <h1>Recent Logo Design</h1>
          <h5>$250/Logo</h5>
        </div>
        <section className="search-results portfolio-cards">
          {featured?.projects?.data?.length >= 1 ? featured.projects.data.filter(item => item.attributes.category === "logodesign").slice(0, 6).map(project => {
            console.log(project)
            return (
              <LogoCard key={project.id} project={project} />
            )
          }) : ""}
        </section>
      </div>
      <div className="topic-showcase container card purp">
        <div className="title">
          <h1>Design Services</h1>
          <h5>$50/hr</h5>
        </div>
        <section className="search-results portfolio-cards">
          {featured?.projects?.data?.length >= 1 ? featured.projects.data.map(project => {
            if (project.attributes.category === "graphicdesign" || project.attributes.category === "design") {
              return (
                <ProjectCard key={project.id} project={project} />
              )
            }
          }) : ""}
        </section>
      </div>
    </section>
    <section className="cta">
      <div className="title container">
        <h2>Looking for something more specific?</h2>
        <p>Browse my portfolio by these use cases</p>
        <div className="badges">
          {topics?.data?.length > 0 ? topics.data.map(topic => {
            return (
              <TopicBadge key={topic.id} topic={topic} />
            )
          }) : ""}
        </div>
        <p>Browse my portfolio by these technologies and languages</p>
        <div className="badges">
          {languages?.data?.length > 0 ? languages.data.map(lang => {
            return (
              <LanguageBadge key={lang.id} language={lang} onClick={() => updateSearch(lang.name)} />
            )
          }) : ""}
          {software?.data?.length > 0 ? software.data.map(soft => {
            return (
              <SoftwareBadge key={soft.id} software={soft} onClick={() => updateSearch(soft.name)} />
            )
          }) : ""}
        </div>
        <p>Or get in touch!</p>
        <a href="/contact"><div className="button">Contact Me</div></a>
      </div>
      <ImageCloud />
    </section>
    <section className="chooser">
      <div className="topic-showcase testimonials container card purp">
        <div className="title">
          <h1>Testimonials</h1>
        </div>
        <section className="search-results">
          {featured?.testimonials.data?.length >= 1
            ? featured.testimonials.data.map(testimonial => {
              return (
                <TestimonialCard key={testimonial.id} testimonial={testimonial} />
              )
            }) : ""}
        </section>
      </div>
    </section>
  </React.Fragment >);
}