import React, { useState } from 'react'
import TopicBadge from '../../badges/topic/TopicBadge'

export default function TopicCards(props) {
  let [topics] = useState(props.topics)
  return (
    <React.Fragment>
      <div className="card badges topic-showcase container purp">
        <div className="title">
          <h1>Topic Results</h1>
          <p>The below pages contain aggregated projects, blogs, or technologies relevant to your search</p>
          <div className="badges center">
            {topics.map(topic => {
              return (
                <TopicBadge topic={topic} />
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
