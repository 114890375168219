import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom'
import './App.scss';
import Header from './header/Header';
import Footer from './footer/Footer';

import ContactPage from '../pagerouter/contact/Contact';
import DesignPage from '../pagerouter/design/Design';
import DevelopmentPage from '../pagerouter/development/Development';
import LandingPage from '../pagerouter/landing/Landing';
import ResumePage from '../pagerouter/resume/Resume';
import BeeHive from '../pagerouter/beehive/beehive';
import Post from '../pagerouter/posts/Posts';
import Blog from '../pagerouter/blog/Blog';
import Topic from '../pagerouter/topic/Topic';
import Filter from '../pagerouter/filter/Filter';
import Sitemap from '../pagerouter/sitemap/Sitemap';

function App() {
    let [AppClassString, setAppClassString] = useState("landing");
    let location = useLocation();

    function onRouteChanged() {
        let App = document.querySelector(".App");
        let navPageClassList = (window.location.pathname !== "/" ? window.location.pathname.split('/') : ["landing"])
        let classString = ""

        // Clear Classes
        while (App.classList.length > 0) {
            App.classList.remove(App.classList.item(0));
        }

        // Add New ones
        App.classList.add("App")

        navPageClassList.forEach(each => {
            if (each !== "") {
                App.classList.add(each)
                classString += (" " + each)
            }
        })

        setAppClassString(classString);
    }

    useEffect(() => {
        onRouteChanged()
    }, [location]);

    return (
        <div className={"App " + (window.location.pathname !== "/" ? AppClassString : "landing")}>
            <Header />
            <Switch>
                <Route exact path="/design"><DesignPage /></Route>
                <Route exact path="/development"><DevelopmentPage /></Route>
                <Route exact path="/resume"><ResumePage /></Route>
                <Route exact path="/contact"><ContactPage /></Route>
                <Route exact path="/beehive"><BeeHive /></Route>
                <Route exact path="/blog/:bid" component={Blog}></Route>
                <Route exact path="/topic/:tid" component={Topic}></Route>
                <Route exact path="/filter/:fid" component={Filter}></Route>
                <Route exact path="/sitemap" component={Sitemap}></Route>
                <Route exact path="/"><LandingPage /></Route>
            </Switch>
            <Footer></Footer>
        </div>
    )
}

export default withRouter(App);