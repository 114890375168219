import React from 'react';
import './Footer.scss';
import { navLinks } from '../header/Header';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';

export default function Footer() {
    let headerLinks = navLinks()
    return (<React.Fragment>
        <section className="footer container col">
            <img className="footer logo" src={logo} />
            <div className="button-con center">
                <ul>
                    {headerLinks.map(link => {
                        return (
                            <Link key={link.label} className="button clean" to={link.path}>{link.label}</Link>
                        )
                    })}
                    <a className="button clean" href="#">Return to Top</a>
                </ul>
            </div>
        </section>
    </React.Fragment>);
}
