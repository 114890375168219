import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import rehypeRaw from 'rehype-raw'
import "./Blog.scss";
import ReactMarkdown from 'react-markdown'
import Badge from 'react-simple-badges';
import SoftwareBadge from '../../components/badges/software/SoftwareBadge';
import HelmetSeo from '../../components/seo/seo'

export default function Blog() {
  let [blog, setBlog] = useState(null)
  let [topics, setTopics] = useState(null)
  let [relatedBlogs, setRelatedBlogs] = useState(null)
  let [error, setError] = useState(null)
  let location = useLocation();

  let [seo, setSEO] = useState(null)
  let [opengraph, setOpenGraph] = useState(null)
  let [twitter, setTwitter] = useState(null)

  useEffect(() => {
    const getBlog = async () => {
      let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            blogs (filters: {slug: {eq: "` + location.pathname.split("/")[location.pathname.split("/").length - 1] + `"}}) {
              data {
                id
                attributes {
                  title
                  content
                  slug
                  website
                  github
                  image {
                    data {
                      attributes {
                        url
                        ext
                      }
                    }
                  }
                  technologies {
                    data {
                      id
                      attributes {
                        slug
                        name
                        label
                      }
                    }
                  }
                  topics {
                    data {
                      id
                      attributes {
                        title
                        slug
                        blogs {
                          data {
                            id
                            attributes {
                              title
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting blog info")
      }
      setBlog(resp?.data?.blogs?.data?.length > 0 ? resp.data.blogs.data[0] : null);
    }

    getBlog()
  }, [])

  useEffect(() => {
    if (blog) {
      const getTopics = async () => {
        let resp = await fetch(`${process.env.REACT_APP_API_HOST}/graphql`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: `query{
            topics {
              data {
                id
                attributes {
                  title
                  slug
                  blogs {
                    data {
                      id
                      attributes {
                        title
                        slug
                        website
                        github
                      }
                    }
                  }
                }
              }
            }
          }
    ` }),
        }).then(resp => resp.json());

        if (resp !== null) {
          setError("")
        } else {
          setError("Error while getting blog info")
        }
        setTopics(resp?.data?.topics ? resp.data.topics : null);

      }
      getTopics()
    }
  }, [blog])

  useEffect(() => {
    if (blog) {
      let res = blog?.attributes?.topics?.data?.map(topic => {
        let relatedBlogs = []
        let set = topic.attributes.blogs.data.map(blog => { return (blog) })
        relatedBlogs.push(...set)
        return (relatedBlogs)
      })
      // Flatten array into one dimension
      let relatedBlogsArr = res.flat()
      // filter out to unique values using blog.title
      let relatedBlogsSet = relatedBlogsArr.filter((blog, index, self) =>
        index === self.findIndex((t) => (
          t.attributes.title === blog.attributes.title
        ))
      )

      setRelatedBlogs(relatedBlogsSet)
    }
  }, [blog])

  return (<React.Fragment>
    {/* ‍<HelmetSeo seo={seo} opengraph={opengraph} twitter={twitter} /> */}
    <section className="blog">
      <div className="container">
        {blog ? <React.Fragment>
          <div className="pane-title">
            <ul><li><h1>{blog.attributes.title} - Project Writeup</h1></li></ul>
          </div>
          <div className="featured-image">
            <img src={blog.attributes.image.data.attributes.url} />
          </div>
          <div className="content">
            <h3>{blog.attributes.title}</h3>
            <div className="button-con">
              {blog.attributes.website ? <a onClick={(e) => e.stopPropagation()} target="_blank" href={blog.attributes.website} className="button clean"><i className="fad fa-browser"></i> Live Website</a> : ""}
              {blog.attributes.github && blog.attributes.github !== "private" ? <a onClick={(e) => e.stopPropagation()} target="_blank" href={blog.attributes.github} className="button clean"><i className="fab fa-github"></i> Github</a> : ""}
              {blog.attributes.github && blog.attributes.github === "private" ? <a onClick={(e) => e.stopPropagation()} target="_blank" href={blog.attributes.github} className="button clean disabled"><i className="fab fa-github"></i> Private Repo</a> : ""}
            </div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{blog.attributes.content ? blog.attributes.content : ""}</ReactMarkdown>
          </div>
        </React.Fragment> : ""}
      </div>
      <div className="sidebar">
        <ul className="badges">
          <li>Tagged Technologies</li>
          {blog?.attributes?.technologies?.data?.length > 0 ? blog.attributes.technologies.data.map(tech => {
            return (
              <SoftwareBadge key={tech.id} software={tech} />
            )
          }) : ""}
        </ul>
        {topics?.data?.length > 0 ?
          <ul>
            <li>Other Project Collections</li>
            {topics.data.map(topic => {
              return (
                <li key={topic.id}><a href={"/topic/" + topic.attributes.slug}>{topic.attributes.title}</a></li>
              )
            })}
          </ul>
          : ""}
        <ul>
          <li>Related Links</li>
          {relatedBlogs?.data?.length > 0 ? relatedBlogs.data.map(blog => {
            return (
              <li key={blog.id}><a href={"/blog/" + blog.attributes.slug}>{blog.attributes.title}</a></li>
            )
          }) : ""}
        </ul>
      </div>
    </section>
  </React.Fragment>);
}
