import React from 'react'
import logopattern from '../../assets/Ball-pattern.png';
import './LogoBall.scss';

export default function LogoBall() {
  return (
    <React.Fragment>
      <div className="logo-ball">
        <img className="pattern" src={logopattern} />
        <img className="repeat" src={logopattern} />
        <img className="again" src={logopattern} />
      </div>
    </React.Fragment>
  )
}
