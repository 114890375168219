import React, { useState } from 'react'

export default function InquiryForm(props) {
  const [radio, setRadio] = useState(null);
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.currentTarget));
    const formData = new FormData(e.currentTarget);

    if (!formData.get('name') && !formData.get('return') && !formData.get('subject') && !formData.get('content') && !radio) {
      setError("Missing required fields")
    } else {
      const additional_interests = props.serviceInterest;
      data.append('additional_interests', String(additional_interests).replace(",", ", "));

      let resp = await fetch('https://api.kylediggs.com/api/email/send', {
        method: 'POST',
        body: data,
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while sending email")
      }
    }
  }

  return (
    <React.Fragment>
      <form className="inquiry form" onSubmit={(e) => onSubmit(e)}>
        {error ?
          <div className="status-container">
            <p className="status danger">
              <i class="fas fa-heart-broken"></i> {error}
            </p>
            <div className="close" onClick={() => setError(null)}>
              <i class="far fa-times"></i>
            </div>
          </div>
          : error !== null ?
            <div className="status-container">
              <p className="status ok">
                <i class="fas fa-mailbox"></i> Email sent successfully!
              </p>
              <div className="close" onClick={() => setError(null)}>
                <i class="far fa-times"></i>
              </div>
            </div>
            : ""}
        <input name="name" type="text" placeholder="Name*" required></input>
        <input name="return" type="email" placeholder="Email Address*" required></input>
        <input name="subject" type="text" placeholder="Subject*" required></input>
        <input name="website" type="text" placeholder="Website URL"></input>
        <textarea name="content" placeholder="Message*" required />
        <p><strong>Primary Interest*:</strong></p>
        <div className="radio-container">
          <label htmlFor="graphicdesign"><input type="radio" onClick={(e) => setRadio(e.value)} name="primary_interest" id="graphicdesign" value="graphicdesign" required />
            Graphic Design</label>
          <label htmlFor="website"><input type="radio" name="primary_interest" id="website" value="website" required />
            Web Development</label>
          <label htmlFor="logodesign"><input type="radio" name="primary_interest" id="logodesign" value="logodesign" required />
            Logo Design</label>
          <label htmlFor="other"><input type="radio" name="primary_interest" id="other" value="other" required />
            Other</label>
        </div>
        <button onClick={(e) => { props.onSelectedServices(e) }} className="button purple" type="button">Additional Service Interests ({props.serviceInterest?.length ? <strong>{props.serviceInterest.length}</strong> : "0"})</button>
        <button className="button purple" type="submit" value="Submit">Submit</button>
      </form>
    </React.Fragment>
  )
}
