import React from 'react';
import BlogCard from './BlogCard.js';
import '../../../components/cards/Card.scss';

export default function BlogCards(props) {
  return (
    <React.Fragment>
      <div className="topic-showcase container card purp">
        <div className="title">
          <h1>Blog Results</h1>
        </div>
        <div className="search-results">
          {props?.blogs?.map(blog => {
            return (
              <BlogCard blog={blog} />
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
