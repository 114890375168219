import React, { useState } from 'react';
import './Resume.scss';
import '../../app/App.scss';
import languages from './languages.json';
import software from './software';
import Badge from 'react-simple-badges';
import '../../components/badges/Badge.scss';

import Github from './resumeimages/github.svg';
import LinkedIn from './resumeimages/linkedin.svg';
import Portfolio from './resumeimages/coffee.svg';

export default function ResumePage() {
  let d = new Date();
  let yn = d.getFullYear();

  return (<section id="ResumePage">
    <div className="resume-container">
      <div className="body">
        <div className="left col">
          <div className="col">
            <h1>Kyle Diggs</h1>
            <h5>Contact@KyleDiggs.com</h5>
            <span>Resume last updated August 2022</span>
          </div>
          <div className="col ">
            <a className="head-button" href="https://github.com/Kyle772" target="_"><img src={Github} alt="Github" /><span className="print-only">/Kyle772 <br />10 commits/day average!</span></a>
            <a className="head-button" href="https://www.linkedin.com/in/kylediggs/" target="_"><img src={LinkedIn} alt="LinkedIn" /><span className="print-only">/in/KyleDiggs</span></a>
          </div>
          <div className="about">
            <h4>About</h4>
            <p>I specialize in full-stack web development with a preference for creative front-end work. I have experience developing custom SaaS web apps alongside a handful of different CMSes and have an eye for clean layouts. My wide ranging background gives me good perspective on planning, system architecture, and documentation creation. Whether I'm working as a technician or a project lead I am a force of nature that aims to enable everyone around me to put their best foot forward.</p>
          </div>
          <h4>Education / Software</h4>
          <div className="education">
            <h6>Many bootcamps with a focus on:</h6>
            <p>Full-Stack Web Development</p>
            <h6>Codecademy Pro Paths:</h6>
            <p>Python, TypeScript, JavaScript, HTML5, CSS3 + SCSS, ReactJS, Angular</p>
            <h6>Self Taught:</h6>
            <p>ReactJS, API + Database design, CSS Animations, NextJS, SEO</p>
            <h6>Arts Institute Mentorships in:</h6>
            <p>Graphic Design, Modern Arts, Illustration, and Screenprinting.</p>
            <h6>Fluent in:</h6>
            <p>Python, JavaScript, React, React Context API, JQuery, Postgresql, SCSS modules, Git</p>
            <h6>Comfortable working with:</h6>
            <p>JIRA, Confluence, Notion, Postman, Material UI, Bootstrap, Webflow, Shopify, WooCommerce, WordPress, GCP, Adobe XD, Illustrator, Photoshop, After Effects</p>
          </div>
        </div>
        <div className="right col">
          <div className="resume-flex-con">
            <h4 className="">Work Experience</h4>
            <h4>Jul 2014 – August 2022</h4>
          </div>
          <h5 className="">RS-R</h5>
          <span>Full Stack Development - eCommerce - Mar 2019 - Present</span>
          <ul>
            <li>Created a fully custom eCommerce application powered by NextJS and Strapi with custom payment and fullfillment integrations</li>
            <li>Enterprise level eCommerce experience with API integrations for custom functionality</li>
            <li>Provided competant solutions for the following: Bin packing, Geocoding, UPS, Authorize.net, Shipstation, and basic devops for a production-ready server</li>
          </ul>
          <h5 className="">Kyle Diggs Development and Design</h5>
          <span>Owner - Digital Media Services and Product development - Jan 2016 - Present</span>
          <ul>
            <li>Provided consultations for non-tech-savvy clients to translate their business needs into actionable plans for both digital and physical</li>
            <li>Specializing in ecommerce I have consistently and reliably delivered results to at least 100 small businesses in need of guidance</li>
            <li>Gained immense and wide spread experience with most mainstream web platforms, design software solutions, and product development</li>
          </ul>
          <h5 className="">MintGoldDust</h5>
          <span>Full Stack Development - NFT Platform - Aug 2021 - Jul 2022</span>
          <ul>
            <li>Responsible for planning and building out web pages and back end functionality from provided designs and business requirements</li>
            <li>Gained experience with NextJS, DevOps, Enterprise database management, product and event launches</li>
            <li>Spear-headed many project sprints and documented systems and processes for the team using JIRA and Confluence</li>
          </ul>
          <h5>Desert fulfillment and Logistics</h5>
          <span>Product Development, System Administration - Jul 2018 - Mar 2019</span>
          <ul>
            <li>Got hands on experience with in-house product development, manufacturing, fulfillment, and marketing processes</li>
            <li>Maintained 12+ shopify stores and provided tech support</li>
            <li>Networking installation and system administration for the office</li>
            <li>Used python for large-scale data validation, cleanup, and CRM</li>
          </ul>
          <h5>PrizeRebel</h5>
          <span>Graphic Design and Front End Development - Nov 2018 - Dec 2021</span>
          <ul>
            <li>Developed custom web pages with a focus on improving the UX across mobile and desktop</li>
            <li>Provided technical consulting to improve development workflow</li>
            <li>Designed and coded marketting materials for monthly email blasts</li>
          </ul>
          <h5 className="">Gamer Queue</h5>
          <span>Chief Technology Officer - Social Media Platform - Jan 2018 - Jun 2019</span>
          <ul>
            <li>Outsourcing and planning sprints as well as major feature additions</li>
            <li>Custom built platform using GCP, Python, Jinja, JS and JQuery</li>
            <li>Designed and implemented custom and performant feed algorithms</li>
            <li>Successfully developed an MVP that was acquisitioned</li>
          </ul>
          <h5>Evasive Motorsports</h5>
          <span>Front End Development - 6 Month Internship - Jul 2015 - Jan 2016</span>
          <ul>
            <li>Improved front page load times by 60%; increasing conversions</li>
            <li>Reimplemented Flash elements with native web components</li>
            <li>Created custom CSS animations to improve site UX</li>
          </ul>
          <h5>Disneyland Parks</h5>
          <span>Trainer, Employee Safety POC, documentation creation - Jul 2014 - Nov 2017</span>
          <ul>
            <li>Handled training, supervising, and the creation of documentation for individual roles; detailed reference material for other trainers as well as job aids for cast members</li>
            <li>Comfortable working in hectic environments handling foot traffic in the thousands</li>
          </ul>
          {/* <div className="resume-flex-con">
            <h4 className="">Contract Development</h4>
            <h4>Aug 2015 – Aug 2020</h4>
          </div>
          <h5>RS-R</h5>
          <ul>
            <li>WordPress maintenance, support, and consultation for updates, upgrades, and database management</li>
            <li>Implementation of eCommerce solutions for improved logistics and day-to-day business operations.</li>
            <li>Built out pages for a full product line launch.</li>
          </ul>
          <h5>JRock Media</h5>
          <ul>
            <li>A small agency local to Palm Springs California specializing in building out websites for small businesses in the area.</li>
            <li>Built out and maintained WordPress websites with custom themes and plugins</li>
            <li>Custom design and development solutions for businesses, authentic historical collections for museum sales, Dentistry, Real Estate, and the health industry.</li>
          </ul>
          <div className="resume-flex-con">
            <h4 className="">Contract Design</h4>
            <h4>Jan 2012 – Sep 2021</h4>
          </div>
          <h5>Prize Rebel</h5>
          <ul>
            <li> Prototyped and Designed animated gifs using stock assets with custom animations in Adobe After Effects.</li>
            <li>Prototyped and Designed custom landing pages, stats pages, user dashboards, and various site elements to improve brand perception and website performance.</li>
          </ul>
          <h5>Various Work</h5>
          <ul>
            <li>Product dev: T-shirt design, Large format print design</li>
            <li>Marketing: Social media content creation, Posters, Framed artwork, and Postcards</li>
            <li>Branding: Logo design, website branding, and stationary for businesses</li>
          </ul>
          <h5>Anthony Cioffi Design</h5>
          <ul>
            <li>Put in hundreds of hours of one-on-one work designing 50+ assets for production and sales alongside Mr. Cioffi.</li>
            <li>Worked on projects alongside Mr. Cioffi from start to finish in physical and digital mediums.</li>
            <li>Became completely fluent in Illustrator and learned a lot of cool tricks along the way.</li>
          </ul> */}
        </div>
      </div>
    </div>
    <button className="print-badge" onClick={() => window.print()}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zm-16-88c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z" />
      </svg>
      <div>
        <span>This page is print friendly!</span>
        <span>Click here to print</span>
      </div>
    </button>
  </section>);
}