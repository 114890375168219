import React, { useState, useEffect } from 'react'

export default function QuoteForm(props) {
  let [radio, setRadio] = useState(null);
  let [info, setInfo] = useState(null);
  let [error, setError] = useState(null);
  let [uploaded, setUploaded] = useState(null);

  const radioInfo = {
    graphicdesign: <HowToQuoteGraphicDesign />,
    website: <HowToQuoteWebDev />,
    logodesign: <HowToQuoteLogoDesign />,
    other: <HowToQuoteOther />
  }

  useEffect(() => {
    setInfo(null)
  }, [radio])

  const showInfo = () => {
    setInfo(radioInfo[radio?.value]);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let source = new URLSearchParams(new FormData(e.currentTarget));
    source.append('subject', 'Quote Requested!')

    let formData = new FormData(e.currentTarget);
    if (!formData.get('name') && !formData.get('return') && !formData.get('content') && !radio) {
      setError("Missing required fields")
    } else {
      let resp = await fetch('https://api.kylediggs.com/api/email/send', {
        method: 'POST',
        body: source,
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while sending email")
      }
    }
  }

  return (
    <React.Fragment>
      <form className="quote form" onSubmit={(e) => onSubmit(e)}>
        {error ?
          <div className="status-container">
            <p className="status danger">
              <i class="fas fa-heart-broken"></i> {error}
            </p>
            <div className="close" onClick={() => setError(null)}>
              <i class="far fa-times"></i>
            </div>
          </div>
          : error !== null ?
            <div className="status-container">
              <p className="status ok">
                <i class="fas fa-mailbox"></i> Email sent successfully!
              </p>
              <div className="close" onClick={() => setError(null)}>
                <i class="far fa-times"></i>
              </div>
            </div>
            : ""}
        <input name="name" type="text" placeholder="Name*" required></input>
        <input name="return" type="email" placeholder="Email Address*" required></input>
        <p><strong>What do you need done?:</strong></p>
        <textarea name="content" placeholder="Your Message*" />
        <div className="upload-container">
          <div className="info">
            <p><strong>If you have prepared assets:</strong></p>
            <p>Please include links to your files in your message.</p>
          </div>
        </div>
        <div className="upload-container">
          <p><strong>Project Type*:</strong></p>
          <button onClick={(e) => showInfo()} className={radio !== null ? "button clean quotes" : "button clean quotes hide"} type="button"><i className="far fa-info-circle"></i> How are quotes calculated?</button>
        </div>
        <div className="radio-container">
          <label htmlFor="graphicdesign"><input type="radio" onChange={(e) => setRadio(e.target)} name="primary_interest" id="graphicdesign" value="graphicdesign" required />
            Graphic Design</label>
          <label htmlFor="website"><input type="radio" onChange={(e) => setRadio(e.target)} name="primary_interest" id="website" value="website" required />
            Web Development</label>
          <label htmlFor="logodesign"><input type="radio" onChange={(e) => setRadio(e.target)} name="primary_interest" id="logodesign" value="logodesign" required />
            Logo Design</label>
          <label htmlFor="other"><input type="radio" onChange={(e) => setRadio(e.target)} name="primary_interest" id="other" value="other" required />
            Other</label>
        </div>
        {info ? info : ""}
        <button className="submit button purple">Submit</button>
      </form>
    </React.Fragment>
  )
}

export function HowToQuoteGraphicDesign() {
  return (
    <React.Fragment>
      <p>Graphic Design is charged at $50/hour</p>
    </React.Fragment>
  )
}

export function HowToQuoteWebDev() {
  return (
    <React.Fragment>
      <p>Web Development is charged at $75/hour</p>
    </React.Fragment>
  )
}

export function HowToQuoteLogoDesign() {
  return (
    <React.Fragment>
      <p>Logo Designs are charged out at $250 per logo sample requested</p>
    </React.Fragment>
  )
}

export function HowToQuoteOther() {
  return (
    <React.Fragment>
      <p>Misc tasks are usually charged on a per project basis. Reach out and I'll get back to you with a quote!</p>
    </React.Fragment>
  )
}


