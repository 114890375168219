import React, { useState } from 'react'
import './Testimonials.scss'

export default function TestimonialCard(props) {
  let [testimonial] = useState(props.testimonial)

  return (
    <React.Fragment>
      <div key={testimonial.id} className={testimonial.attributes.content.length > 250 ? "card testimonial long" : testimonial.attributes.content.length > 150 ? "card testimonial med" : testimonial.attributes.content.length < 50 ? "card testimonial short" : "card testimonial"}>
        <p className="content">"{testimonial.attributes.content}"</p>
        <h5 className="author">{testimonial.attributes.author}</h5>
        <p className="position">{testimonial.attributes.position}</p>
      </div>
    </React.Fragment>
  )
}
