import React, { useState } from 'react';
import Badge from 'react-simple-badges';
import SoftwareBadge from '../../badges/software/SoftwareBadge';
import './BlogCard.scss';

export default function BlogCard(props) {
  let [blog] = useState(props.blog);
  let [expanded, setExpanded] = useState(false);

  const toggleExpanded = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded(!expanded)
  }

  console.log(blog)

  return (
    <React.Fragment>
      <div key={blog.attributes.id} className="blog card button">
        <a href={"/blog/" + blog.attributes.slug}>
          <div className="img-con">
            <img className="image" src={blog.attributes.image.data.attributes.url} />
          </div>
          <div className="title-con">
            <h5 className="title">{blog.attributes.title}</h5>
            <div className="button-con">
              <div className="button clean"><i className="fad fa-file-search"></i> Project Write-up</div>
              {blog.attributes.website ? <a onClick={(e) => e.stopPropagation()} target="_blank" href={blog.attributes.website} className="button clean"><i className="fad fa-browser"></i> Live Website</a> : ""}
              {blog.attributes.github && blog.attributes.github !== "private" ? <a onClick={(e) => e.stopPropagation()} target="_blank" href={blog.attributes.github} className="button clean"><i className="fab fa-github"></i> Github</a> : ""}
              {blog.attributes.github && blog.attributes.github === "private" ? <a onClick={(e) => e.stopPropagation()} target="_blank" href={blog.attributes.github} className="button clean disabled"><i className="fab fa-github"></i> Private Repo</a> : ""}
            </div>
          </div>
        </a>
        <div className="badges">
          {blog?.attributes?.technologies?.data?.length > 0 && !expanded ? <React.Fragment>
            {blog.attributes.technologies.data.slice(0, 3).map(tech => {
              return (
                <SoftwareBadge key={tech.id} software={tech} />
              )
            })}
            {blog.attributes.technologies.data.length > 3 ?
              <a onClick={(e) => toggleExpanded(e)} className="badge button empty"><div>And {blog.attributes.technologies.data.length - 3} More</div></a>
              : ""}
          </React.Fragment> : expanded ? <React.Fragment>
            {blog.attributes.technologies.data.map(tech => {
              return (
                <SoftwareBadge key={tech.id} software={tech} />
              )
            })}
            <a onClick={(e) => toggleExpanded(e)} className="badge button empty"><div>Collapse Extras</div></a>
          </React.Fragment> : ""}
        </div>
      </div>
    </React.Fragment>
  )
}
