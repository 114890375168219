import React, { useState } from 'react'
import Badge from 'react-simple-badges';

export default function LanguageBadge(props) {
  let [lang] = useState(props.language)
  return (
    <React.Fragment>
      <a href={"/filter/" + lang.attributes.slug} key={lang.attributes.name} onClick={props.onClick} className="badge button lang">
        <Badge className="badge1" name={lang.attributes.name} label={lang.attributes.label} backgroundColor="#25252b" ></Badge>
        <Badge className="badge2" name={lang.attributes.name} label={lang.attributes.label}></Badge>
      </a>
    </React.Fragment>
  )
}
