import React, { useState, useEffect } from 'react';
import "./Design.scss";
import ProjectCard from '../../components/cards/project/ProjectCard'

export default function DesignPage() {
    let [portfolioItems, setPortfolioItems] = useState(null)
    let [error, setError] = useState(null)

    useEffect(() => {
        const getPortfolioItems = async () => {
            let resp = await fetch('https://api.kylediggs.com/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `query{
                projects (where: {category: "design"}) {
                    id
                    title
                    slug
                    description
                    category
                    image {
                      url
                      ext
                    }
                    technologies {
                      id
                      slug
                      name
                      label
                    }
                }
            }
        ` }),
            }).then(resp => resp.json());

            if (resp !== null) {
                setError("")
            } else {
                setError("Error while getting project info")
            }
            setPortfolioItems(resp?.data?.projects ? resp.data.projects : null);
        }

        getPortfolioItems()
    }, [])

    return (<React.Fragment>
        <section className="portfolio-cards">
            <div className="container">
                {portfolioItems ? portfolioItems.map(proj => {
                    return (
                        <ProjectCard project={proj} />
                    )
                }) : ""}
            </div>
        </section>
    </React.Fragment>);
}