import React, { useState } from 'react'

export default function LogoCard(props) {
  let [project] = useState(props.project)
  return (
    <React.Fragment>
      <div key={project.id} className="card">
        <a>
          <div className="img-con">
            <img className="image" src={project.attributes.image.data.attributes.url} />
          </div>
          <p className="content">{project.attributes.description}</p>
        </a>
      </div>
    </React.Fragment>

  )
}
