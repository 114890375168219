import React, { useState } from 'react'
import Badge from 'react-simple-badges';

export default function TopicBadge(props) {
  let [topic] = useState(props.topic)
  return (
    <React.Fragment>
      <a href={"/topic/" + topic.attributes.slug} key={topic.attributes.title} className="badge button lang">
        <Badge className="badge1" name={topic.attributes.title} label={topic.attributes.title} backgroundColor="#25252b" ></Badge>
        <Badge className="badge2" name={topic.attributes.title} label={topic.attributes.title}></Badge>
      </a>
    </React.Fragment>
  )
}
