import React, { useState } from 'react'
import Badge from 'react-simple-badges';

export default function SoftwareBadge(props) {
  let [soft] = useState(props.software)
  return (
    <React.Fragment>
      <a href={"/filter/" + soft.attributes.slug} key={soft.attributes.name} onClick={props.onClick} className="badge button soft">
        <Badge className="badge1" name={soft.attributes.name} label={soft.attributes.label} backgroundColor="#25252b"></Badge>
        <Badge className="badge2" name={soft.attributes.name} label={soft.attributes.label}></Badge>
      </a>
    </React.Fragment>
  )
}
