import React, { Component } from 'react';
import axios from 'axios';
import './Posts.scss';

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            posts: []
        }
        console.log(props)
    }

    componentDidMount() {
        this.setState({ post: this.getPost_byId(this.state.pid) });
    }

    async getPost_byId(pid) {
        this.setState({ loading: true })
        let postdata = null;
        console.log('https://www.kylediggs.com/wp-json/wp/v2/posts/' + pid);
        postdata = await axios.get('https://www.kylediggs.com/wp-json/wp/v2/posts/' + pid)
            .then(function(response) {
                postdata = response.data;
                console.log(postdata)
                return postdata;
            })
            .catch(function(error) {
                // handle error
                console.log(error);
            })
        this.setState({
            posts: [postdata],
            loading: false
        })
    }

    render(pid) {
        return (<section className="post-container">
            { this.state.posts.length >= 1 ?  this.state.posts.map(post => {return(
                <div className="post">
                    <div className="pane-title">
                        <ul><li><h1>{ post.title.rendered } - Development Blog</h1></li></ul>
                    </div>
                    <div className="content" dangerouslySetInnerHTML={{__html: post.content.rendered }}></div>
                </div>
            )}) : 
            <div className="post">
                <div className="pane-title">
                    <ul><li>This is a title</li></ul>
                </div>
                <h1 className="title"></h1>
                <div className="content"></div>
            </div>
             }
            <div className="sidebar">
                <ul>
                    <li>Similar posts</li>
                    <li>Similar posts</li>
                    <li>Similar posts</li>
                    <li>Similar posts</li>
                    <li>Similar posts</li>
                </ul>
            </div>
        </section>);
    }
}

export default Post;