import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './beehive.scss';
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import logo from '../../assets/logo.svg';
import linkDuoTone from '../../assets/link-duotone.svg';

export default function BeeHive() {
    let editorRef = useRef(null)
    let [questions, setQuestions] = useState(null)
    let [qLoading, setQLoading] = useState(null)
    let [images, setImages] = useState(null)
    let [iLoading, setILoading] = useState(null)
    let [selectedImage, setSelectedImage] = useState(logo)
    let [wallpaperPage, setWallpaperPage] = useState(1)
    let [questionsPage, setQuestionsPage] = useState(1)
    let [emojiMap, setEmojiMap] = useState({
        joy: "😊",
        confident: "😎",
        analytical: "🤓",
        tentative: "🤔",
        fear: "😱",
        anger: "🤬",
        sadness: "😓"
    })

    useEffect(() => {
        const getQuestions = async () => {
            setQLoading(true)
            let data = null;
            console.log('/pyapi/askReddit/get/100/' + questionsPage);
            data = await axios.get('/pyapi/askReddit/get/100/' + questionsPage)
                .then(function (response) {
                    data = response.data;
                    console.log(data)
                    return data;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
            setQLoading(false)
            setQuestions(data)
        }

        questions = getQuestions();
    }, [questionsPage])

    useEffect(() => {
        const getImages = async () => {
            setILoading(true)
            let data = null;
            console.log('/pyapi/wallpaper/get/50/' + wallpaperPage);
            data = await axios.get('/pyapi/wallpaper/get/50/' + wallpaperPage)
                .then(function (response) {
                    data = response.data;
                    console.log(data)
                    return data;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
            setILoading(false)
            setImages(data)
        }
        images = getImages();
    }, [wallpaperPage])

    const pullImage = async (url) => {
        let data = null;
        console.log('/pyapi/wallpaper?url=' + url);
        data = await axios.get('/pyapi/wallpaper?url=' + url)
            .then(function (response) {
                data = response.data;
                return data;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

        const editorInstance = editorRef.current.getInstance();
        let path = "/pyapi/static/" + data;
        console.log(editorInstance)
        editorInstance.loadImageFromURL(path, 'image');
        setSelectedImage(path)
    }

    const emojiFromTone = (toneinput) => {
        let translated = emojiMap[toneinput]
        return translated
    }

    const copyTitle = (title) => {
        navigator.clipboard.writeText(title);
    }

    const nextWallpapers = () => {
        let newPage = wallpaperPage + 1
        setWallpaperPage(newPage)
    }
    const prevWallpapers = () => {
        if (wallpaperPage > 0) {
            let newPage = wallpaperPage - 1
            setWallpaperPage(newPage)
        }
    }
    const nextQuestions = () => {
        let newPage = questionsPage + 1
        setQuestionsPage(newPage)
    }
    const prevQuestions = () => {
        if (questionsPage > 0) {
            let newPage = questionsPage - 1
            setQuestionsPage(newPage)
        }
    }


    editorRef = React.createRef();
    return (<React.Fragment>
        <section className="beehive container">
            <div className="images ">
                <h3>Select an Image</h3>
                <div className="button-con">
                    {questionsPage >= 1 ? <button onClick={(e) => prevWallpapers(e)}>&lt;</button> : ""}
                    <button onClick={(e) => nextWallpapers()}>&gt;</button>
                </div>
                <div className="images-container">
                    {images && images.length >= 1 ? images.reverse().map(image => {
                        return (
                            <div onClick={(e) => { pullImage(image[0]) }}><img className="image" src={image[0]} /></div>
                        )
                    }) : ""}
                </div>
            </div>
            <div className="questions ">
                <h3>Questions from the INTERNET</h3>
                <div className="button-con">
                    {questionsPage >= 1 ? <button onClick={() => prevQuestions()}>&lt;</button> : ""}
                    <button onClick={() => nextQuestions()}>&gt;</button>
                </div>
                {questions && questions.length >= 1 ? questions.map(question => {
                    // For some reason JavaScript is implicity converting "[]" to an Array but very randomly. Check then convert
                    if (Array.isArray(question.tones) !== true) {
                        question.tones = JSON.parse(question.tones)
                    }
                    return (<a className="question button clean">
                        <span className="text">{question.title}</span>
                        <div className="tone-con">
                            {question.tones !== [] ? question.tones.map(tone => {
                                return (
                                    <span className="tone button" data-tone={tone.tone_name} data-score={(tone.score * 100).toFixed(0) + "%"}>
                                        {Object.keys(emojiMap).includes(tone.tone_id) ? emojiFromTone(tone.tone_id) : tone.tone_name}
                                    </span>
                                )
                            }) : ""}
                            {question.url !== "" ? <a title="Visit Source" className="button external" href={question.url} target="_"><svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="link" className="svg-inline--fa fa-link fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g className="fa-group"><path className="fa-secondary" fill="currentColor" d="M44.45 252.59l37.11-37.1c9.84-9.84 26.78-3.3 27.29 10.6a184.45 184.45 0 0 0 9.69 52.72 16.08 16.08 0 0 1-3.78 16.61l-13.09 13.09c-28 28-28.9 73.66-1.15 102a72.07 72.07 0 0 0 102.32.51L270 343.79A72 72 0 0 0 270 242a75.64 75.64 0 0 0-10.34-8.57 16 16 0 0 1-6.95-12.6A39.86 39.86 0 0 1 264.45 191l21.06-21a16.06 16.06 0 0 1 20.58-1.74A152.05 152.05 0 0 1 327 400l-.36.37-67.2 67.2c-59.27 59.27-155.7 59.26-215 0s-59.26-155.72.01-214.98z" opacity="0.4"></path><path className="fa-primary" fill="currentColor" d="M410.33 203.49c28-28 28.9-73.66 1.15-102a72.07 72.07 0 0 0-102.32-.49L242 168.21A72 72 0 0 0 242 270a75.64 75.64 0 0 0 10.34 8.57 16 16 0 0 1 6.94 12.6A39.81 39.81 0 0 1 247.55 321l-21.06 21.05a16.07 16.07 0 0 1-20.58 1.74A152.05 152.05 0 0 1 185 112l.36-.37 67.2-67.2c59.27-59.27 155.7-59.26 215 0s59.27 155.7 0 215l-37.11 37.1c-9.84 9.84-26.78 3.3-27.29-10.6a184.45 184.45 0 0 0-9.69-52.72 16.08 16.08 0 0 1 3.78-16.61z"></path></g></svg></a> : ""}
                            {question.title !== "" ? <a title="Copy text" className="button copy" onClick={() => { copyTitle(question.title) }}><svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="copy" className="svg-inline--fa fa-copy fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><g className="fa-group"><path className="fa-secondary" fill="currentColor" d="M352 96V0H152a24 24 0 0 0-24 24v368a24 24 0 0 0 24 24h272a24 24 0 0 0 24-24V96z" opacity="0.4"></path><path className="fa-primary" fill="currentColor" d="M96 392V96H24a24 24 0 0 0-24 24v368a24 24 0 0 0 24 24h272a24 24 0 0 0 24-24v-40H152a56.06 56.06 0 0 1-56-56zM441 73L375 7a24 24 0 0 0-17-7h-6v96h96v-6.06A24 24 0 0 0 441 73z"></path></g></svg></a> : ""}
                        </div>
                    </a>
                    )
                }) : ""}
            </div>
            <div ref={editorRef} className="editor ">
                <ImageEditor
                    ref={editorRef}
                    includeUI={{
                        loadImage: {
                            path: selectedImage,
                            name: "image"
                        },
                        menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],
                        initMenu: 'filter',
                        uiSize: {
                            width: '100%',
                            height: '100%'
                        },
                        menuBarPosition: 'bottom'
                    }}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70
                    }}
                    usageStatistics={false}
                />
            </div>
        </section>
    </React.Fragment>)
}