import { now } from 'moment';
import React, { useState, useEffect } from 'react'
import './Sitemap.scss';
import moment from 'moment'
import LogoBall from '../../components/logo-ball/LogoBall';

export default function Sitemap() {
  let [topics, setTopics] = useState(null);
  let [technologies, setTechnologies] = useState(null);
  let [languages, setLanguages] = useState(null);
  let [blogs, setBlogs] = useState(null);
  let [error, setError] = useState(null);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      let resp = await fetch('https://api.kylediggs.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query{
            topics {
              slug
              updatedAt
            }
            technologies {
              slug
              updatedAt
            }
            languages {
              slug
              updatedAt
            }
            blogs {
              slug
              updatedAt
            }
          } 
      ` }),
      }).then(resp => resp.json());

      if (resp !== null) {
        setError("")
      } else {
        setError("Error while getting topic info")
      }
      setTopics(resp?.data?.topics ? resp.data.topics : null);
      setTechnologies(resp?.data?.technologies ? resp?.data?.technologies : null)
      setLanguages(resp?.data?.languages ? resp?.data?.languages : null)
      setBlogs(resp?.data?.blogs ? resp?.data?.blogs : null)
      setIsLoading(false)
    }
    setIsLoading(true)
    getData()
  }, [])



  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  const clickToCopy = () => {
    let data = document.querySelector(".content code").innerHTML;
    data = `<?xml version="1.0" encoding="UTF-8"?>` + String(data);
    copyTextToClipboard(data)
    setError("Validated .XML text copied to clipboard.")
  }

  return (
    <React.Fragment>
      <section className="xml container">
        <div className="sitemap-container">
          <h1>Dynamic XML</h1>
          <p>Custom made to dynamically generate an XML document from the CMS attached to the website.</p>
          {error ? <div className="status-container">
            <p className="status ok"><i className="fad fa-gifts"></i> {error}</p>
            <div className="close" onClick={() => setError(null)}><i className="far fa-times"></i></div>
          </div>
            : ""}
          <div className="content" onClick={(e) => clickToCopy(e)}>
            {isLoading ? <LogoBall /> : <code>
              <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
                {topics ? topics.map(topic => {
                  return (
                    <url>
                      <loc>{"https://www.kylediggs.com/topic/" + topic.slug}</loc>
                      <lastmod>{moment(topic.updatedAt).format("YYYY-MM-DD")}</lastmod>
                    </url>
                  )
                }) : ""}
                {technologies ? technologies.map(technology => {
                  return (
                    <url>
                      <loc>{"https://www.kylediggs.com/filter/" + technology.slug}</loc>
                      <lastmod>{moment(technology.updatedAt).format("YYYY-MM-DD")}</lastmod>
                    </url>
                  )
                }) : ""}
                {languages ? languages.map(language => {
                  return (
                    <url>
                      <loc>{"https://www.kylediggs.com/topic/" + language.slug}</loc>
                      <lastmod>{moment(language.updatedAt).format("YYYY-MM-DD")}</lastmod>
                    </url>
                  )
                }) : ""}
                {blogs ? blogs.map(blog => {
                  return (
                    <url>
                      <loc>{"https://www.kylediggs.com/blog/" + blog.slug}</loc>
                      <lastmod>{moment(blog.updatedAt).format("YYYY-MM-DD")}</lastmod>
                    </url>
                  )
                }) : ""}
              </urlset>
            </code>}
          </div>
          <div className="input-con">
          </div>
        </div>
      </section>
      <section className="container button-con">
        <a href="https://www.KyleDiggs.com/sitemap.xml"><button className="button">Click to go to Sitemap.xml</button></a>
      </section>
    </React.Fragment>
  )
}
