import React, { useState } from 'react'
import ProjectCard from './ProjectCard';

export default function ProjectCards(props) {
  let [projects] = useState(props.projects)
  return (
    <React.Fragment>
      <div className="topic-showcase container card purp">
        <div className="title">
          <h1>Relevant Services</h1>
        </div>
        <div className="search-results">
          {projects?.map(project => {
            return (
              <ProjectCard project={project} />
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
